.cca-logo{
    height: 50px;
    width: 50px;
    margin-left: 25px;
    object-fit: contain;
}

.wdct-logo{
    padding-top: 8px;
    margin-left: 0.5rem;
    height: 60px;
    width:  60px;
    object-fit: contain;
}

.navbar{
    background-color: transparent;
    margin-top: -15px;
}

.navbar-brand{
    padding-top: 25px;
}

.ham{
    background-color: transparent;
    color: #00FFF0;
    font-size: 3rem;
}

button{
    border: none;
    padding: 0;
}

.navDrop ul{
    padding-left: 0;
}

.navDrop li{
    list-style: none;
}

.hero{
    margin-top: -120px;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #07192F;
    font-family: 'Inter', sans-serif;
}

.heroText{
    margin-top: -5%;
    color: white;
    line-height: 87.14px;
}

.heroText h1{
    font-size: 72px;
}

.heroText h3{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.hRBtn{
    background-color: #00FFF0;
    color: #07123F;
}

.hRBtn:hover{
    background-color: #00FFF0;
    color: #07123F;
    transform: scale(1.05, 1.05);
}

.hBtn{
    border-color: #00FFF0;
    color: #00FFF0;
}

.hBtn:hover{
    border-color: #00FFF0;
    transform: scale(1.05, 1.05);
    background-color: transparent;
    color: #00FFF0;
}

.learn{
    margin-top: -120px;
    max-width: 80%;
    box-shadow:0 0 0 2px #ccc;
    background-color: white;
    color: black;
    border-radius: 15px;
    padding: 20px 40px;
    box-shadow: 0px 2px 20px rgba(7, 25, 47, 0.15);
}

.description h2{
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.descTxt{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.descTxtday{
    font-size: 1.2rem;
    font-weight: 600;
}

.day{
    font-family: 'Inter', sans-serif;
    display: flex;
    text-align: left;
}

.card{
    box-shadow: 0px 2px 30px rgba(7, 25, 47, 0.15);
}

.certificate,
.wdctCorner,
.interview{
    height: 220px;
    object-fit: contain;
}

.certificate{
    padding: 1.5rem;
}

.wdctCorner{
    padding: 1.5rem;
}

.interview{
    padding: 2rem;
}

@media(max-width: 600px){
    .certificate,
    .wdctCorner,
    .interview{
        max-height: 180px;
        width: 100%;
    }
    .interview{
        padding: 1.5rem;
    }
}

/* .mentor{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
} */
.mentorImg{
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25)  ;
    border-radius: 50%;
    width:  165px;
    height: 165px;
    object-fit: cover;
}

.mentorTxt{
    /* display: flex; */
    align-items: flex-end;
}

.mentorTxt h4{
    font-size: 1.25rem;
}

.coordinatorCard{
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.coordinatorImg{
    display: block;
    margin: 15px auto;
    height: 150px;
    width:  150px;
    border-radius: 50%;
    background-color: #eeeeee !important;
    object-fit: cover;
}

.footer{
    margin-top: 10rem;
    padding: 1rem;
    background-color: #000000;
    width: 100%;
  }

.footer-text{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    /* line-height: 29.05; */
}

.form-box{
    padding: 10px;
    background: #F5F5F5;

}

.form-button{
    margin-left: 8px;
    margin-top: 10px; 
    color: #00FFF0;

}
  
.left-form-box{
    margin-right: 25px;
}

.regBtn{
    background-color: #07123F;
    color: #00FFF0;
    border: none;
    padding: 0.75rem 4rem;
}

.regBtn:hover{
    background-color: #07123F;
    color: #00FFF0;
}

.heart{
    color: dodgerblue;
}
@media (max-width: 991px){
    .card{
        margin: 1rem 0;
    }
}
@media (max-width: 590px){
    .cca-logo{
        height: 40px;
        width: 40px;
        margin-left: 25px;
    }
    .wdct-logo{
        height: 60px;
        width: 60px;
        /* margin-top: 10px; */
    }
    .heroText h1{
        font-size: 3rem;
        line-height: 65px;
    }
    .mentorTxt h4{
        margin-top: -0.5rem;
        font-size: 1.1rem;
    }
    .heroText h3{
        font-size: .9rem;
    }
    .ham{
        font-size: 2rem;
    }
    .hBtn{
        height: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        
    }
    .hRBtn{
        height: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        
    }
    .learn{
        max-width: 92%;
        padding: 2rem 1.5rem ;
        box-shadow: 0px 2px 10px rgba(7, 25, 47, 0.15);
    }
    .description h2{
        font-size: 1.75rem;
    }
    .descTxt{
        font-size: 1.2rem;
    }
    .descTxtday{
        font-size: 1.2rem;
        font-weight: 600;
        color: #666;
    }
    .day{
        font-size: 1rem;
    }
    .card{
        box-shadow: 0px 2px 10px rgba(7, 25, 47, 0.15);
    }
    .description p{
        font-size: 1rem;
    }
    .mentorImg{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
    }
    .mentorTxt{
        text-align: center;
    }
    .mentorLinks{
        margin-left: -5%;
    }
}

input,
textarea,
select{
    background-color:  #f5f5f5 !important;
    height: 3rem;
    border: 0;
    cursor: pointer;
}
input::placeholder,
option::placeholder{
    font-size: 14px !important;
}

.centerV{
    display: flex;
    justify-content: start;
    align-items: center;
}
.centerH{
    display: flex;
    justify-content: center;
    align-items: center;
}
.offimg{
    width:  2rem;
    height: 2rem;
    object-fit: contain;
}
.offa{
    font-size: 1.3rem;
    text-decoration: none;
}
.fa-brands{
    text-decoration: none;
}
.fa-facebook,
.fa-facebook:hover{
    color: #3b5998;
}

.fa-behance,
.fa-behance:hover{
    color: dodgerblue;
}
.fa-instagram,
.fa-instagram:hover{
    color: #e4405f;
}
.fa-linkedin,
.fa-linkedin:hover{
    color: #0077b5;
}
.fa-youtube,
.fa-youtube:hover{
    color: #ff0000;
}

.offcanvas-title img{
    width:  3rem;
    height: 3rem;
    object-fit: contain;
}